new Vue({
	el: "#fSignIn",
	data: {
		loading: false,
		user: {
			login: null,
			password: null
		},
		errors: {},
		error: null,
	},
	methods: {
		doLogin: function () {
			this.loading = true;
			this.$http.post('/api/login', this.user)
					.then(response => {
						console.log('response',response);
						let body = response.body;
						if (body.hasOwnProperty("done")) {
							if (body.done) {
								window.location.href = body.path;
							} else {
								if (body.hasOwnProperty("errors")) {
									this.errors = body.errors;
								} else {
									this.error = "Неверный логин либо пароль!";
								}
							}
						}
						this.loading = false;
					})
					.catch(App.handlers.httpError(() => { this.loading = false; }));
		},
		testEnter: function () {
			this.loading = true;
			this.$http.post('/api/enter-test')
					.then(response => {
						console.log('response',response);
						let body = response.body;
						if (body.hasOwnProperty("done")) {
							if (body.done) {
								window.location.href = body.path;
							} else {
								if (body.hasOwnProperty("errors")) {
									this.errors = body.errors;
								} else {
									this.error = "Неверный логин либо пароль!";
								}
							}
						}
						this.loading = false;
					})
					.catch(App.handlers.httpError(() => { this.loading = false; }));
		},
	}
});

